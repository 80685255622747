export const dividirArreglo = (arr, black_menu_quantity) => {
  let resultado = [];
  let subarreglo = [];
  const quantity_per_page = parseInt(black_menu_quantity.data) || 4;
  for (let i = 0; i < arr.length; i++) {
    subarreglo.push(arr[i]);
    if ((i + 1) % quantity_per_page === 0) {
      resultado.push(subarreglo);
      subarreglo = [];
    }
  }

  // Añadir el subarreglo restante si no está vacío
  if (subarreglo.length > 0) {
    resultado.push(subarreglo);
  }

  return resultado;
};
