import React from "react";
import styled from "styled-components";
import {
  Prices,
  Section,
  SectionName,
  SectionPrices,
} from "../style/styled-components";

const AguasGaseosasCtn = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  padding-bottom: 2px;
  width: 100%;
  &.aguas {
    align-items: self-end;
  }
  &.otros {
    width: 60%;
    margin: 0 auto;
  }
`;

const AguasGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 95%;
  h3 {
    display: inline;
    font-family: "Enriqueta", serif;
  }
  &.cafeteria {
    width: 100%;
  }
`;
const OtrosDetail = styled.div`
  display: flex;
  margin-left: 5px;
`;

const AguasGaseosas = ({ data, classname }) => {
  return (
    <AguasGaseosasCtn className={classname}>
      {!Array.isArray(data) ? (
        <AguasGroup className={data?.id == "cafeteria" && "cafeteria"}>
          {data?.id !== "cafeteria" && <h3>{data.id?.toUpperCase()}</h3>}
          {data?.data.map((prod, key) => (
            <Section className="bebidas" key={key}>
              <SectionPrices>
                {parseInt(prod.price) > 0 && (
                  <Prices>
                    <SectionName className="bebidas">
                      {prod.name?.toUpperCase()}{" "}
                      {classname !== "otros" && prod.detail?.toUpperCase()}
                    </SectionName>
                    <span className="dots"></span>
                    <span>${prod.price}</span>
                  </Prices>
                )}
                {classname === "otros" && (
                  <OtrosDetail>
                    {prod.detail.charAt(0) +
                      prod.detail.charAt(1).toUpperCase() +
                      prod.detail.slice(2).toLowerCase()}
                  </OtrosDetail>
                )}
              </SectionPrices>
            </Section>
          ))}
        </AguasGroup>
      ) : (
        data.map((d, key) => (
          <AguasGroup key={key}>
            <h3>{d?.id?.toUpperCase()}</h3>
            {d.data.map((prod, key) => (
              <Section className="bebidas" key={key}>
                <SectionPrices>
                  {parseInt(prod.price) > 0 && (
                    <Prices>
                      <SectionName className="bebidas">
                        {prod.name?.toUpperCase()} {prod.detail?.toUpperCase()}
                      </SectionName>
                      <span className="dots"></span>
                      <span>${prod.price}</span>
                    </Prices>
                  )}
                </SectionPrices>
              </Section>
            ))}
          </AguasGroup>
        ))
      )}
    </AguasGaseosasCtn>
  );
};
export default AguasGaseosas;
