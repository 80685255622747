import React from "react";
import {
  Prices,
  TypePlate,
  Section,
  SectionName,
  SectionPrices,
  SectionContainer,
  PlateInformation,
  GuarnicionList,
  SalmonCTN,
} from "../style/styled-components";

const MenuCategory = ({ products, isColumn, category = null }) => {
  let prod = [];
  if (!Array.isArray(products)) {
    for (const key in products) {
      if (products.hasOwnProperty(key)) {
        prod.push(products[key]);
      }
    }
  } else {
    prod = products;
  }
  return (
    <SectionContainer
      className={isColumn ? "column" : null}
      $carnes={
        [
          "carnes",
          "pescados",
          "pastas",
          "salsa a eleccion",
          "salsas especiales",
        ].includes(category)
          ? "true"
          : null
      }
    >
      {category && category.includes("guarniciones") && (
        <h3>{category.toUpperCase()}</h3>
      )}
      {prod.length > 0 && typeof prod[0] !== "string" ? (
        prod.map((prod, key) => (
          <Section
            key={key}
            className={isColumn ? "column" : null}
            $category={category === "aves" && true}
            $salmon={
              prod.name?.toLowerCase().includes("salmon") && key === 3 && true
            }
          >
            {prod.name?.toLowerCase().includes("salmon") ? (
              <SalmonCTN>
                <SectionName className="salmon">
                  <Prices>
                    {prod.name?.toUpperCase()}
                    <span className="dots"></span>
                    <span>${prod.price}</span>
                  </Prices>
                  <PlateInformation className={category}>
                    {prod.detail}
                  </PlateInformation>
                </SectionName>
              </SalmonCTN>
            ) : (
              <>
                <SectionName
                  className={
                    category?.toLowerCase() === "salsas especiales" &&
                    "salsas_especiales"
                  }
                >
                  <span>{prod.name?.toUpperCase()}</span>
                  {prod.detail && category === "carnes" && (
                    <PlateInformation className={category}>
                      {prod.detail.length > 5 ? <br></br> : null}
                      {prod.detail}
                    </PlateInformation>
                  )}
                </SectionName>
                {prod.detail && category !== "carnes" && (
                  <PlateInformation>{prod.detail}</PlateInformation>
                )}
                <SectionPrices>
                  {parseInt(prod.price) > 0 && (
                    <Prices>
                      <TypePlate>Individual</TypePlate>
                      <span className="dots"></span>
                      <span>${prod.price}</span>
                    </Prices>
                  )}
                  {parseInt(prod.sharingPrice) > 0 && (
                    <Prices>
                      <TypePlate>Para compartir</TypePlate>
                      <span className="dots"></span>
                      <span className="product-price">
                        ${prod.sharingPrice}
                      </span>
                    </Prices>
                  )}
                </SectionPrices>
              </>
            )}
          </Section>
        ))
      ) : (
        <GuarnicionList>
          <ul>
            {prod.map((prod, key) => (
              <li key={key}>
                <SectionName>{prod.toUpperCase()}</SectionName>
              </li>
            ))}
          </ul>
        </GuarnicionList>
      )}
    </SectionContainer>
  );
};

export default MenuCategory;
