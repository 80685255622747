import React from "react";
import styled from "styled-components";
import {
  OutsideBorder,
  GroupSections,
  Sections,
} from "../../style/styled-components";
import MenuCategory from "../MenuCategory";
import AguasGaseosas from "../AguasGaseosas";
import Cubiertos from "../Cubiertos";

const GaseosasSection = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  align-self: center;
`;

const PostresBebidas = ({
  exclusiveBorders,
  exlusiveProds,
  postres_bebidas,
  cubierto,
}) => {
  const [postres, cafeteria, aguas, aguas_saborizadas, gaseosas, otros] =
    postres_bebidas;
  const aguas_aguasSaborizadas = [aguas_saborizadas, aguas];
  return (
    <OutsideBorder>
      <GroupSections>
        <Sections
          $fullwidth={exlusiveProds.includes(postres.order) ? "true" : null}
          $exclusiveborder={
            exclusiveBorders.includes(postres.order) ? "true" : null
          }
        >
          <h2>{postres.id.toUpperCase()}</h2>
          <MenuCategory
            products={postres.data}
            isColumn={exlusiveProds.includes(postres.order) ? true : false}
          />
        </Sections>
        <Sections
          className="cafeteria"
          $fullwidth={exlusiveProds.includes(cafeteria.order) ? "true" : null}
          $exclusiveborder={
            exclusiveBorders.includes(cafeteria.order) ? "true" : null
          }
        >
          <h2>{cafeteria.id.toUpperCase()}</h2>
          <AguasGaseosas data={cafeteria} />
        </Sections>
        <Sections className="bebidas gaseosas" $fullwidth={true}>
          <h2>{"bebidas".toUpperCase()}</h2>
          <GaseosasSection>
            <AguasGaseosas data={gaseosas} />
            <AguasGaseosas data={aguas_aguasSaborizadas} classname={"aguas"} />
          </GaseosasSection>
          <AguasGaseosas data={otros} classname={"otros"} />
        </Sections>
        <Cubiertos data={cubierto.data} />
      </GroupSections>
    </OutsideBorder>
  );
};

export default PostresBebidas;
