import React, { useState } from "react";
import {
  Bar,
  LogoContainer,
  Logo,
  HamburgerContainer,
  Hamburger,
  NavbarHamburgerContainer,
  SideBarContainer,
} from "../style/styled-components";
import SideBar from "./SideBar";

const NavBar = ({ has_SideBar_Background }) => {
  const [showSideBar, setShowSideBar] = useState(false);

  const toogleIcon = () => {
    setShowSideBar(!showSideBar);
  };

  return (
    <Bar>
      <NavbarHamburgerContainer>
        <LogoContainer>
          {/* <a href="/menu">
            <Logo src={require("../assets/Logo.png")} />
          </a> */}
          <Logo src={require("../assets/Logo.png")} />
        </LogoContainer>
        {/* <HamburgerContainer onClick={toogleIcon}>
          <Hamburger className={showSideBar ? "open" : null} />
        </HamburgerContainer> */}
      </NavbarHamburgerContainer>
      {/* {showSideBar && <SideBar />} */}
    </Bar>
  );
};

export default NavBar;
