import React from "react";
import {
  OutsideBorder,
  GroupSections,
  Sections,
} from "../../style/styled-components";
import detail from "../../Constants.js";
import MenuCategory from "../MenuCategory";
import Cubiertos from "../Cubiertos";
import styled from "styled-components";

export const TitleDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  span {
    /* margin: auto; */
    margin-top: -10px;
    align-self: center;
    font-family: "Montserrat", sans-serif;
    font-size: 9px;
  }
  &.carnes {
    span {
      padding-bottom: 15px;
    }
  }
  &.salsa_eleccion {
    padding-bottom: 10px;
    font-size: 9px;
  }
`;

const Minutas = ({ exclusiveBorders, exlusiveProds, m, cubierto }) => {
  return (
    <OutsideBorder>
      <GroupSections>
        <Sections
          $fullwidth={exlusiveProds.includes(m.order) ? "true" : null}
          $exclusiveborder={exclusiveBorders.includes(m.order) ? "true" : null}
        >
          <TitleDetail>
            <h2>{m.id.toUpperCase()}</h2>
            <span>{detail.GUARNICION_DETAIL}</span>
          </TitleDetail>
          <MenuCategory
            products={m.data}
            isColumn={exlusiveProds.includes(m.order) ? true : false}
          />
        </Sections>
        <Cubiertos data={cubierto.data} />
      </GroupSections>
    </OutsideBorder>
  );
};

export default Minutas;
