import React from "react";
import {
  Prices,
  TypePlate,
  Section,
  EntriesSectionName,
  SectionPrices,
  SectionContainer,
  PlateInformation,
} from "../../style/styled-components";

const EntreeView = ({ products, isColumn, category = null }) => {
  const prod = [];
  for (const key in products) {
    if (products.hasOwnProperty(key)) {
      prod.push(products[key]);
    }
  }
  return (
    <SectionContainer>
      {prod.length > 0 &&
        prod.map((prod, key) => (
          <Section key={key} className={isColumn ? "column" : null}>
            <SectionPrices>
              {parseInt(prod.price) > 0 || parseInt(prod.sharingPrice) > 0 ? (
                parseInt(prod.price) > 0 && parseInt(prod.sharingPrice) > 0 ? (
                  <div>
                    <EntriesSectionName>
                      {prod.name?.toUpperCase()}
                    </EntriesSectionName>
                    <Prices>
                      <TypePlate>Individual</TypePlate>
                      <span className="dots"></span>
                      <span>${prod.price}</span>
                    </Prices>
                    <Prices>
                      <TypePlate>Para compartir</TypePlate>
                      <span className="dots"></span>
                      <span>${prod.sharingPrice}</span>
                    </Prices>
                    {prod.detail ? (
                      <PlateInformation>{prod.detail}</PlateInformation>
                    ) : null}
                  </div>
                ) : parseInt(prod.price) > 0 ? (
                  <div>
                    <Prices $entrees={"entrees"}>
                      <EntriesSectionName
                        className={
                          prod.name
                            .trim()
                            .toUpperCase()
                            .includes(" RADICHETA Y AJO")
                            ? "radicheta"
                            : null
                        }
                      >
                        {prod.name?.toUpperCase()}
                      </EntriesSectionName>
                      {!prod.name
                        .trim()
                        .toUpperCase()
                        .includes(" RADICHETA Y AJO") && (
                        <span className="dots"></span>
                      )}
                      <span>${prod.price}</span>
                    </Prices>
                    {prod.detail ? (
                      <PlateInformation>{prod.detail}</PlateInformation>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <Prices $entrees={"entrees"}>
                      <EntriesSectionName>
                        {prod.name?.toUpperCase()}
                      </EntriesSectionName>
                      <span className="dots"></span>
                      <span className="product-price">
                        ${prod.sharingPrice}
                      </span>
                    </Prices>
                    <PlateInformation>(Para compartir)</PlateInformation>
                    <div>
                      {prod.detail && !prod.detail.includes("compartir") ? (
                        <PlateInformation>{prod.detail}</PlateInformation>
                      ) : null}
                    </div>
                  </div>
                )
              ) : null}
            </SectionPrices>
          </Section>
        ))}
    </SectionContainer>
  );
};

export default EntreeView;
