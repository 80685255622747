import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
const root = ReactDOM.createRoot(document.getElementById("root"));
const errorHandler = (error, errorInfo) => {
  console.error("Error capturado:", error, errorInfo);
  // Aquí podrías enviar el error a un servicio de logging, por ejemplo
};

root.render(
  <StrictMode>
    <ErrorBoundary onError={errorHandler}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
