import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      isBoundaryReady: false, // Nuevo estado para controlar si el boundary está listo
    };
  }

  componentDidCatch(error, errorInfo) {
    // Actualizar el estado para mostrar el mensaje de error
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
    // También puedes enviar el error a un servicio de registro de errores
    // logErrorToMyService(error, errorInfo);
  }

  componentDidMount() {
    // Marcar el boundary como listo después de que se monte
    this.setState({ isBoundaryReady: true });
  }

  render() {
    if (this.state.hasError) {
      // Puedes personalizar la interfaz de usuario de acuerdo al error
      return (
        <div>
          <h1>¡Ups! Algo salió mal.</h1>
          <p>
            Por favor, intenta recargar la página o contacta al soporte técnico.
          </p>
        </div>
      );
    }
    // Si el boundary aún no está listo, muestra un indicador de carga
    if (!this.state.isBoundaryReady) {
      return <div>Cargando...</div>;
    }
    // Si no hay error y el boundary está listo, renderiza los componentes hijos normales
    return this.props.children;
  }
}

export default ErrorBoundary;
