import React from "react";
import {
  OutsideBorder,
  GroupSections,
  ColumnSection,
  Sections,
} from "../../style/styled-components";
import MenuCategory from "../MenuCategory";
import Cubiertos from "../Cubiertos";
import { TitleDetail } from "./Minutas";

const Pastas = ({ exclusiveBorders, exlusiveProds, pasta_salsa, cubierto }) => {
  return (
    <OutsideBorder>
      <GroupSections $carnes={true}>
        <h2>{"pastas".toUpperCase()}</h2>
        <ColumnSection>
          {pasta_salsa.map((groupedMenu, k) => (
            <Sections
              key={k}
              className="pastas"
              $fullwidth={
                exlusiveProds.includes(groupedMenu.order) ? "true" : null
              }
              $exclusiveborder={
                exclusiveBorders.includes(groupedMenu.order) ? "true" : null
              }
              $salsa_eleccion={
                ["salsa a eleccion"].includes(groupedMenu.id) ? "true" : null
              }
            >
              {groupedMenu.id.includes("salsa") &&
                groupedMenu.id.toLowerCase() !== "salsa a eleccion" && (
                  <h3 className="salsas_especiales">
                    {groupedMenu.id.toUpperCase()}
                  </h3>
                )}
              {groupedMenu.id.toLowerCase() === "salsa a eleccion" && (
                <TitleDetail className="salsa_eleccion">
                  <h3>{groupedMenu.id.toUpperCase()}</h3>
                  <span>Incluida en las pastas</span>
                </TitleDetail>
              )}
              <MenuCategory
                products={groupedMenu.data}
                isColumn={
                  exlusiveProds.includes(groupedMenu.order) ? true : false
                }
                category={groupedMenu.id}
              />
            </Sections>
          ))}
        </ColumnSection>
        <Cubiertos data={cubierto.data} />
      </GroupSections>
    </OutsideBorder>
  );
};

export default Pastas;
