import React from "react";
import styled from "styled-components";
import {
  Prices,
  Section,
  SectionName,
  SectionPrices,
} from "../style/styled-components";

const CubiertosCtn = styled.div`
  margin: 0 auto;
  margin-top: 15px;
  width: 35%;
  text-align: center;
  h3 {
    display: inline-block;
    font-family: "Enriqueta", serif;
    justify-self: center;
    align-content: center;
    align-self: center;
    border-bottom: 1px solid white;
    text-align: center;
    font-size: 11px;
    margin-bottom: 5px;
  }
`;
const Cubiertos = ({ data = [] }) => {
  const [cubiertos, ...rest] = data;
  return (
    <CubiertosCtn>
      <h3>SERVICIO DE MESA</h3>
      <Prices className="cubiertos">
        <SectionName className="cubiertos">{"valor".toUpperCase()}</SectionName>
        <span className="dots"></span>
        <span>${cubiertos?.price}</span>
      </Prices>
    </CubiertosCtn>
  );
};

export default Cubiertos;
