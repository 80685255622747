import styled, { createGlobalStyle, keyframes } from "styled-components";
import Bernier from "../fonts/Commercial/TTF/BERNIERRegular-Regular.ttf";

//

// Define la animación de rotación
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

// Estilos para el icono de carga
export const LoadingIcon = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #767676;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
  color: white;
`;

// ------------------------------------------

export const FontStyle = createGlobalStyle`
  @font-face {
    font-family: 'plates';
    src: url('../../public/BebasNeue-Regular.ttf')  format('truetype');
  }
  @font-face {
    font-family: 'type_plate';
    src: url('../../public/LibreBaskerville-Regular.ttf')  format('truetype');
  }
  @font-face {
    font-family: 'category_title';
    src: url('../../public/BERNIER™ Regular.ttf')  format('truetype');
  }
`;
// ----------------- NAVBAR --------------------

export const Bar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  padding-top: 15px;
  background-color: black !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  z-index: 1;
`;

export const NavbarHamburgerContainer = styled.div`
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 45vw;
  &.vegano {
    width: 20px;
  }
  &.gluten {
    width: 20px;
    padding-right: 10px;
  }
`;
export const HamburgerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 5rem;
  cursor: pointer;
  margin-right: 1.5rem;
  z-index: 1;
`;

export const Hamburger = styled.div`
  width: 25px;
  height: 4px;
  background-color: #fff;
  position: relative;
  transition: transform 0.3s ease;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 4px;
    background-color: #fff;
    transition: transform 0.3s ease;
  }

  &::before {
    top: -10px;
  }

  &::after {
    top: 10px;
  }
  &.open {
    transform: rotate(50deg);

    &::before {
      transform: rotate(-100deg) translate(-10px, -3px);
    }

    &::after {
      visibility: hidden;
      transform: rotate(-100deg) translate(10px, -1px);
    }
  }
`;

// ------------------------- CARROUSEL -----------------------------

export const CarouselContainer = styled.div``;

export const CarouselContainerImg = styled.div``;

export const CarouselImg = styled.img`
  max-width: 100%;
  height: 99.6vh;
  opacity: 0;
  transition: 1s;
  &.loaded {
    opacity: 1;
  }
`;

// ----------------------- SIDEBAR ----------------------------------
export const SideBarContainer = styled.div`
  /* backdrop-filter: blur(10px); */
  background-color: rgba(0, 0, 0, 0.5);
`;

export const MenuOptions = styled.div`
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.5);
  &.back {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;
export const MenuList = styled.ul`
  margin-left: 3rem;
  height: 80vh;
  list-style-type: none;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  padding: 0;
`;
export const StyledLi = styled.li`
  color: white;
  margin-bottom: 8px;
  font-size: 24px;
`;

// ------------------------ MENU -------------------------------------------
export const MenuContainer = styled.div`
  display: block;
  margin-top: 71px;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  font-size: 11px;
`;

export const SectionsContainer = styled.div`
  margin-top: 20px;
  padding: 5px;
`;

export const OutsideBorder = styled.div`
  border: 1px solid #9f7c10;
  padding: 5px;
  margin-bottom: 3rem;
`;

export const GroupSections = styled.div`
  border: 1px solid #9f7c10;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  h2 {
    display: flex;
    align-self: center;
    font-family: "Enriqueta", serif;
    justify-content: center;
    border-bottom: 1px solid white;
    width: 100%;
  }
  ${(props) =>
    props.$carnes &&
    `
    padding-top: 0;
    padding-rigth: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    `}
`;

export const ColumnSection = styled.div`
  column-count: 2;
  break-inside: avoid;
  column-gap: 30px;
`;

export const Sections = styled.div`
  break-inside: avoid;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;
  h2,
  h3,
  h4 {
    display: flex;
    font-family: "Enriqueta", serif;
    align-items: center;
    border-bottom: 1px solid white;
    text-align: center;
    justify-content: center;
  }
  &.cafeteria {
    align-self: center;
    width: 15rem;
    h2,
    h3 {
      width: 80%;
    }
  }
  &.bebidas {
    display: flex;
    align-self: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }
  &.gaseosas {
    display: flex;
    align-self: flex-start;
    h3 {
      align-self: center;
    }
  }
  &.pastas {
    .salsas_especiales {
    }
  }
  ${(props) =>
    props.$fullwidth &&
    `
    width: 100%;
    column-span: all;
  `}
  ${(props) =>
    props.$exclusiveborder &&
    `
    border: 1px solid #ffffff;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  `}
   ${(props) =>
    props.$salsa_eleccion &&
    `
    width: 75%;
    margin: 0 auto;
  `}
   ${(props) =>
    props.$aves &&
    `
    align-self: center;
  `}
`;

// ---------------- MENU CATEGORY -------------------------------------------------
export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 10px;
  &.column {
    display: block;
    column-count: 2;
    break-inside: avoid;
    column-gap: 30px;
  }
  ${(props) =>
    props.$carnes &&
    `
    padding-top: 0px;
  `}
`;

export const SalmonCTN = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.7rem;
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  break-inside: avoid;
  padding-bottom: 1rem;
  &.bebidas {
    padding-bottom: 0.4rem;
  }
  &.whisky {
    width: 60%;
    margin: auto;
  }
  &.black_menu {
    margin-bottom: 20px;
    width: 100%;
  }
  ${(props) =>
    props.$category &&
    `
    padding-bottom: 30px;
    `}
  ${(props) =>
    props.$salmon &&
    `
  `}
`;
export const SectionName = styled.span`
  font-size: 14px;
  max-width: 100%;
  /* width: 100%; */
  font-family: "Bebas Neue", sans-serif;
  &.salmon {
    max-width: 100%;
  }
  &.bebidas {
    font-size: 12px;
  }
  &.cubiertos {
    font-size: 11px;
    font-family: "Lora", serif;
  }
  &.black_menu {
    color: #9f7c10;
  }
  &.salsas_especiales {
    font-size: 13px;
  }
`;

export const EntriesSectionName = styled.span`
  display: inline-block;
  align-items: center;
  font-size: 14px;
  font-family: "Bebas Neue", sans-serif;
  &.radicheta {
    width: 60%;
  }
`;

export const SectionPrices = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Prices = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .dots {
    flex-grow: 1;
    border-bottom: 1px dotted;
    margin: 0 5px;
    margin-bottom: 2px;
    min-width: 0px;
  }
`;
export const TypePlate = styled.span`
  font-size: 9px;
  font-family: "Libre Baskerville", serif;
`;
export const PlateInformation = styled.span`
  margin: 2px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 9px;
  color: white;
  &.carnes {
    padding-left: 5px;
  }
  &.pescados {
    padding-left: 5px;
  }
  &.black_menu {
    display: flex;
    justify-content: center;
    font-size: 8px;
    text-align: center;
  }
  ${(props) =>
    props.$blackmenu &&
    `
    margin-top: 10px;
  `}
`;

export const GuarnicionList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

// =================== CONTACT ==============================================

export const ContactComponent = styled.div`
  margin-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
