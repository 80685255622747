import React, { useEffect, useState } from "react";
import {
  MenuContainer,
  SectionsContainer,
  LoadingIcon,
  LoadingIconContainer,
} from "../style/styled-components";

import Entrees from "./views/Entrees";
import Minutas from "./views/Minutas";

import { onSnapshot } from "firebase/firestore";
import { menuCollection } from "../lib/firebase.collection";
import Aves from "./views/Aves";
import CarnesPescado from "./views/CarnesPescado";
import Pastas from "./views/Pastas";
import PostresBebidas from "./views/PostresBebidas";
import BebidasAlcoholicas from "./views/BebidasAlcoholicas";
import MenuBlack from "./views/MenuBlack";
import { dividirArreglo } from "../utils/dividir_menu_black";

const RealTimeMenu = ({ menu }) => {
  const [menuObject, setMenuObject] = useState([]);
  const [blackMenu, setBlackMenu] = useState([]);
  const [error, setError] = useState(false);
  const exlusiveProds = [5, 9, 10, 13]; // 5: Minutas, 9: Pescados, 10:aves, 13: postres
  const exclusiveBorders = [7, 8, 11.5]; // 7: Salsa para carnes, 8: Guarniciones para acompaniar, 11.5: salsa a eleccion
  const guarnicion_acompanar = {
    id: "guarniciones para acompañar",
    data: [
      "papas fritas",
      "batatas fritas",
      "papas españolas",
      "pure de papa",
      "pure de calabaza",
      "ensalada",
    ],
    order: 7.5,
  };
  const salsa_eleccion = {
    id: "salsa a eleccion",
    data: ["filetto", "crema", "salsa blanca", "mixta"],
    order: 11.5,
  };
  const modifyMenu = (menu_param) => {
    menu_param.push(guarnicion_acompanar, salsa_eleccion);
    const menuObject = {};
    const menu_black = menu_param.find((m) => m.order === 26);
    const black_menu_quantity = menu_param.find((m) => m.order === 100);
    const entradas = menu_param.filter((m) => m.order < 5);
    const minutas = menu_param.find((el) => el.order === 5);
    const carnes_salsaCarne = menu_param.filter((m) =>
      [6, 7].includes(m.order)
    );
    const aves = menu_param.filter((m) => [7.5, 10].includes(m.order));
    const pescado = menu_param.find((el) => el.order === 9);
    const pastas_salsas = menu_param
      .filter((m) => [11, 11.5, 12].includes(m.order))
      .sort((a, b) => a.order - b.order);
    const bebida_postres = menu_param
      .filter((m) => m.order >= 13 && m.order <= 17)
      .sort((a, b) => a.order - b.order);
    const cerveza_tragos = menu_param
      .filter((m) => [18, 19].includes(m.order))
      .sort((a, b) => a.order - b.order);
    const vinos = menu_param
      .filter((m) => [20, 21, 22, 23].includes(m.order))
      .sort((a, b) => a.order - b.order);
    const whisky = menu_param.find((m) => m.order === 24);
    const cubierto = menu_param.find((m) => m.order === 25);
    menuObject.entradas = entradas;
    menuObject.minutas = minutas;
    menuObject.carnes = carnes_salsaCarne;
    menuObject.aves = aves;
    menuObject.pescados = pescado;
    menuObject.pastas = pastas_salsas;
    menuObject.postres = bebida_postres;
    menuObject.cerveza_tragos = cerveza_tragos;
    menuObject.vinos = vinos;
    menuObject.whisky = whisky;
    menuObject.cubierto = cubierto;
    menuObject.menu_black = menu_black;
    setBlackMenu(
      dividirArreglo(menuObject["menu_black"].data, black_menu_quantity)
    );
    setMenuObject(menuObject);
  };
  useEffect(() => {
    if (!menu || menu.length === 0) {
      const unSubscribe = onSnapshot(menuCollection, (snapshot) => {
        const snap = snapshot.docs
          .map((doc) => {
            const { data, order } = doc.data();
            return {
              id: doc.id,
              data,
              order,
            };
          })
          .sort((a, b) => a.order - b.order);
        if (snap.length > 24) {
          modifyMenu(snap);
          setError(false);
        } else {
          setError(true);
        }
      });
      return () => {
        unSubscribe();
      };
    }
    modifyMenu(menu);
  }, [menu]);
  return (
    <>
      <MenuContainer>
        {
          <SectionsContainer>
            {menuObject.hasOwnProperty("entradas") > 0 && !error ? (
              <>
                {blackMenu.map((el, i) => (
                  <MenuBlack key={i} m={el} cubierto={menuObject["cubierto"]} />
                ))}
                <Entrees
                  exclusiveBorders={exclusiveBorders}
                  exlusiveProds={exlusiveProds}
                  m={menuObject["entradas"]}
                  cubierto={menuObject["cubierto"]}
                />
                <Minutas
                  exclusiveBorders={exclusiveBorders}
                  exlusiveProds={exlusiveProds}
                  m={menuObject["minutas"]}
                  cubierto={menuObject["cubierto"]}
                />
                <CarnesPescado
                  exclusiveBorders={exclusiveBorders}
                  exlusiveProds={exlusiveProds}
                  carnes_salsas={menuObject["carnes"]}
                  pescado={menuObject["pescados"]}
                  cubierto={menuObject["cubierto"]}
                />
                <Aves
                  exclusiveBorders={exclusiveBorders}
                  exlusiveProds={exlusiveProds}
                  m={menuObject["aves"]}
                  cubierto={menuObject["cubierto"]}
                />
                <Pastas
                  exclusiveBorders={exclusiveBorders}
                  exlusiveProds={exlusiveProds}
                  pasta_salsa={menuObject["pastas"]}
                  cubierto={menuObject["cubierto"]}
                />
                <PostresBebidas
                  exclusiveBorders={exclusiveBorders}
                  exlusiveProds={exlusiveProds}
                  postres_bebidas={menuObject["postres"]}
                  cubierto={menuObject["cubierto"]}
                />
                <BebidasAlcoholicas
                  exclusiveBorders={exclusiveBorders}
                  exlusiveProds={exlusiveProds}
                  cervezas_tragos={menuObject["cerveza_tragos"]}
                  vinos={menuObject["vinos"]}
                  whisky={menuObject["whisky"]}
                  cubierto={menuObject["cubierto"]}
                />
              </>
            ) : (
              <LoadingIconContainer>
                <LoadingIcon />
              </LoadingIconContainer>
            )}
          </SectionsContainer>
        }
      </MenuContainer>
    </>
  );
};

export default RealTimeMenu;
