import React from "react";
import {
  Sections,
  GroupSections,
  OutsideBorder,
  ColumnSection,
} from "../../style/styled-components";
import EntreeView from "./EntreeView";
import Cubiertos from "../Cubiertos";

const Entrees = ({ exclusiveBorders, exlusiveProds, m, cubierto }) => {
  return (
    <OutsideBorder>
      <GroupSections>
        <ColumnSection>
          {m.map((groupedMenu, key) => (
            <Sections
              key={key}
              $fullwidth={
                exlusiveProds.includes(groupedMenu.order) ? "true" : null
              }
              $exclusiveborder={
                exclusiveBorders.includes(groupedMenu.order) ? "true" : null
              }
            >
              <h2>{groupedMenu.id.toUpperCase()}</h2>
              <EntreeView
                products={groupedMenu.data}
                isColumn={
                  exlusiveProds.includes(groupedMenu.order) ? true : false
                }
                category={"entrees"}
              />
            </Sections>
          ))}
        </ColumnSection>
        <Cubiertos data={cubierto.data} />
      </GroupSections>
    </OutsideBorder>
  );
};

export default Entrees;
