import React from "react";
import {
  OutsideBorder,
  GroupSections,
  Sections,
  Section,
  SectionPrices,
  Prices,
  SectionName,
  PlateInformation,
  Logo,
} from "../../style/styled-components";
import Cubiertos from "../Cubiertos";
import styled from "styled-components";
const BlackMenuTitle = styled.h2`
  margin-bottom: 40px;
  padding-bottom: 5px;
  border-bottom: 1px solid #9f7c10 !important;
  margin-top: 25px;
`;
const LogosContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;
const MenuBlack = ({ m, cubierto }) => {
  return (
    <OutsideBorder>
      <GroupSections>
        <Sections $fullwidth={true}>
          <BlackMenuTitle>{"BLACK MENU".toUpperCase()}</BlackMenuTitle>
          {m.map((prod, key) => (
            <Section className="black_menu" key={key}>
              <SectionPrices>
                {
                  <Prices>
                    <SectionName className="black_menu">
                      {prod.name?.toUpperCase()}
                    </SectionName>
                    <span className="dots"></span>
                    <span>${prod.price}</span>
                  </Prices>
                }
              </SectionPrices>
              {prod.detail.split("!").map((detail, i) => (
                <PlateInformation
                  className="black_menu"
                  $blackmenu={i === 0 ? true : null}
                  key={i}
                >
                  {detail}
                </PlateInformation>
              ))}
              <LogosContainer>
                {prod.type === 0 && (
                  <Logo
                    className="vegano"
                    src={require("../../assets/vegano.png")}
                  />
                )}
                {prod.type === 1 && (
                  <Logo
                    className="gluten"
                    src={require("../../assets/sin_gluten.png")}
                  />
                )}
                {prod.type === 2 && (
                  <>
                    <Logo
                      className="vegano"
                      src={require("../../assets/vegano.png")}
                    />
                    <Logo
                      className="gluten"
                      src={require("../../assets/sin_gluten.png")}
                    />
                  </>
                )}
              </LogosContainer>
            </Section>
          ))}
        </Sections>
        <Cubiertos data={cubierto.data} />
      </GroupSections>
    </OutsideBorder>
  );
};

export default MenuBlack;
