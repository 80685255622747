import React from "react";
import styled from "styled-components";

import {
  OutsideBorder,
  GroupSections,
  Sections,
} from "../../style/styled-components";

import MenuCategory from "../MenuCategory";
import Aditionals from "../Aditionals";
import Cubiertos from "../Cubiertos";
import { TitleDetail } from "./Minutas";
import detail from "../../Constants.js";

const AvesGroup = styled.div`
  display: flex;
`;
const LeftColumn = styled.div`
  width: 100%;
  margin-right: 20px;
`;

const Aves = ({ exclusiveBorders, exlusiveProds, m, cubierto }) => {
  const aves_element = m.find((el) => el.id === "aves");
  const guarnicion = m.find((el) => el.id !== "aves");
  const aves_left_column = aves_element.data.slice(0, 5);
  const aves_right_column = aves_element.data.slice(5);
  return (
    <OutsideBorder>
      <GroupSections>
        <Sections $aves={true}>
          <TitleDetail>
            <h2>{aves_element.id.toUpperCase()}</h2>
            <span>{detail.GUARNICION_DETAIL}</span>
          </TitleDetail>
          <AvesGroup>
            <LeftColumn>
              <MenuCategory products={aves_left_column} />
              <Aditionals aditionals={guarnicion} guarniciones={true} />
            </LeftColumn>
            <MenuCategory
              products={aves_right_column}
              isColumn={false}
              category={"aves"}
            />
          </AvesGroup>
        </Sections>
        <Cubiertos data={cubierto.data} />
      </GroupSections>
    </OutsideBorder>
  );
};

export default Aves;
