import React from "react";
import styled from "styled-components";
import {
  Prices,
  Section,
  SectionName,
  SectionPrices,
  GuarnicionList,
} from "../style/styled-components";

const AditionalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  h3 {
    display: inline;
    font-family: "Enriqueta", serif;
    border-bottom: 1px solid white;
    text-align: center;
  }
  &.bebidasTragos {
    width: 45%;
    flex-direction: column;
    align-self: flex-start;
    border: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  ${(props) =>
    props.$guarnicionaves &&
    `
    margin-top: 25px;
  `}
`;
const Guarniciones = styled.ul``;
const Aditionals = ({ aditionals, guarniciones = false }) => {
  return (
    aditionals && (
      <AditionalsContainer
        className={
          aditionals.id === "guarniciones para acompañar"
            ? "aves_guarnicion"
            : ["tragos", "cervezas"].includes(aditionals.id)
            ? "bebidasTragos"
            : ""
        }
        $guarnicionaves={guarniciones}
      >
        <h3>{aditionals.id.toUpperCase()}</h3>
        {!guarniciones ? (
          aditionals.data.map((prod, key) => (
            <Section
              key={key}
              className={
                ["tragos", "cervezas"].includes(aditionals.id) && "bebidas"
              }
            >
              <SectionPrices>
                {
                  <Prices>
                    <SectionName>{prod.name?.toUpperCase()}</SectionName>
                    <span className="dots"></span>
                    <span>${prod.price}</span>
                  </Prices>
                }
              </SectionPrices>
            </Section>
          ))
        ) : (
          <GuarnicionList>
            <Guarniciones>
              {aditionals.data.map((prod, key) => (
                <li key={key}>
                  <SectionName>{prod.toUpperCase()}</SectionName>
                </li>
              ))}
            </Guarniciones>
          </GuarnicionList>
        )}
      </AditionalsContainer>
    )
  );
};

export default Aditionals;
