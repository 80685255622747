import React from "react";
import {
  Prices,
  Section,
  SectionName,
  SectionPrices,
  PlateInformation,
} from "../style/styled-components";

const Vinos = ({ vinos }) => {
  return vinos.map((prod, key) => (
    <Section key={key}>
      <SectionPrices>
        {parseInt(prod.price) > 0 && (
          <Prices>
            <SectionName>{prod.name?.toUpperCase()}</SectionName>
            <span className="dots"></span>
            <span>${prod.price}</span>
          </Prices>
        )}
        {prod.detail && <PlateInformation>{prod.detail}</PlateInformation>}
      </SectionPrices>
    </Section>
  ));
};

export default Vinos;
