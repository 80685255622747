import React from "react";
import styled from "styled-components";
import {
  OutsideBorder,
  GroupSections,
  Sections,
  Prices,
  Section,
  SectionName,
  SectionPrices,
} from "../../style/styled-components";
import VinosView from "../Vinos";
import Aditionals from "../Aditionals";
import Cubiertos from "../Cubiertos";

const Cervezas = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VinosSection = styled.div`
  h2 {
    align-self: center;
    width: 100%;
  }
`;
const Vinos = styled.div`
  width: 100%;
  justify-content: center;
  display: block;
  column-count: 2;
  break-inside: avoid;
  column-gap: 30px;
`;
const WhiskySection = styled.div`
  h2 {
    align-self: center;
    width: 100%;
  }
`;
const BebidasAlcoholicas = ({
  exclusiveBorders,
  exlusiveProds,
  cervezas_tragos,
  vinos,
  whisky,
  cubierto,
}) => {
  const vinosUpdate = [vinos[0], vinos[3], vinos[1], vinos[4], vinos[2]];
  return (
    <OutsideBorder>
      <GroupSections>
        <Cervezas>
          {cervezas_tragos.map((m, key) => (
            <Aditionals aditionals={m} key={key} />
          ))}
        </Cervezas>
        <VinosSection>
          <h2>{"vinos".toUpperCase()}</h2>
          <Vinos>
            {vinosUpdate.map((m, key) => (
              <Sections key={key}>
                <h2>{m.id.toUpperCase()}</h2>
                <VinosView vinos={m.data} />
              </Sections>
            ))}
          </Vinos>
        </VinosSection>
        <WhiskySection>
          <h2>{whisky.id.toUpperCase()}</h2>
          {whisky.data.map((prod, key) => (
            <Section className="whisky" key={key}>
              <SectionPrices>
                {parseInt(prod.price) > 0 && (
                  <Prices>
                    <SectionName>
                      {prod.name?.toUpperCase()} {prod.detail?.toUpperCase()}
                    </SectionName>
                    <span className="dots"></span>
                    <span>${prod.price}</span>
                  </Prices>
                )}
              </SectionPrices>
            </Section>
          ))}
        </WhiskySection>
        <Cubiertos data={cubierto.data} />
      </GroupSections>
    </OutsideBorder>
  );
};

export default BebidasAlcoholicas;
