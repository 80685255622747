import React from "react";
import {
  OutsideBorder,
  GroupSections,
  Sections,
  ColumnSection,
} from "../../style/styled-components";

import { TitleDetail } from "./Minutas";
import detail from "../../Constants.js";
import MenuCategory from "../MenuCategory";
import Aditionals from "../Aditionals";
import Cubiertos from "../Cubiertos";

const CarnesPescado = ({
  exclusiveBorders,
  exlusiveProds,
  carnes_salsas,
  pescado,
  cubierto,
}) => {
  const [carnes, salsas_carnes] = carnes_salsas;
  return (
    <OutsideBorder>
      <GroupSections>
        <TitleDetail className="carnes">
          <h2>{"carnes".toUpperCase()}</h2>
          <span>{detail.GUARNICION_DETAIL}</span>
        </TitleDetail>
        <ColumnSection $carnes={true}>
          <Sections
            $fullwidth={exlusiveProds.includes(carnes.order) ? "true" : null}
            $exclusiveborder={
              exclusiveBorders.includes(carnes.order) ? "true" : null
            }
            $salsa_eleccion={
              ["salsa a eleccion"].includes(carnes.id) ? "true" : null
            }
          >
            {carnes.id.includes("salsa") && <h3>{carnes.id.toUpperCase()}</h3>}
            <MenuCategory
              products={carnes.data}
              isColumn={exlusiveProds.includes(carnes.order) ? true : false}
              category={carnes.id}
            />
          </Sections>
          <Aditionals aditionals={salsas_carnes} />
        </ColumnSection>
        <TitleDetail className="carnes">
          <h2>{"Pescados".toUpperCase()}</h2>
          <span>{detail.GUARNICION_DETAIL}</span>
        </TitleDetail>
        <Sections
          $fullwidth={exlusiveProds.includes(pescado.order) ? "true" : null}
          $exclusiveborder={
            exclusiveBorders.includes(pescado.order) ? "true" : null
          }
          $salsa_eleccion={
            ["salsa a eleccion"].includes(pescado.id) ? "true" : null
          }
        >
          {pescado.id.includes("salsa") && <h3>{pescado.id.toUpperCase()}</h3>}
          <MenuCategory
            products={pescado.data}
            isColumn={exlusiveProds.includes(pescado.order) ? true : false}
            category={pescado.id}
          />
        </Sections>
        <Cubiertos data={cubierto.data} />
      </GroupSections>
    </OutsideBorder>
  );
};

export default CarnesPescado;
