import { Route, Routes } from "react-router-dom";
import Carousel from "./components/Carousel";
import RealTimeMenu from "./components/RealTimeMenu";
import Contact from "./components/Contact";
import About from "./components/About";
import NavBar from "./components/NavBar";

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        {/* <Route
          path="/"
          element={
            <Carousel images={["resto.jpg", "resto2.jpg", "resto3.jpg"]} />
          }
        /> */}
        <Route path="/menu" element={<RealTimeMenu />} />
        {/* <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} /> */}
      </Routes>
    </>
  );
}

export default App;
